import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { ChevronDown, Icon } from "@whitelabel-webapp/shared/design-system";
import { NoDeliveryMethod } from "@app/domains/checkout";
import { useState } from "react";

import * as S from "./styles";

export const DeliveryMethod: React.VFC = () => {
  const { deliveryMethod } = useAddress();

  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <S.Wrapper>
      <S.Text onClick={handleOnOpen}>
        {deliveryMethod?.isDeliveryMode() && "Entrega"}
        {deliveryMethod?.isTakeoutMode() && "Retirada"}
        {!deliveryMethod && "Retirada"}
      </S.Text>
      <Icon size="s" component={ChevronDown} />
      <NoDeliveryMethod open={open} onClose={handleOnClose}></NoDeliveryMethod>
    </S.Wrapper>
  );
};
