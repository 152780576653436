import { CustomerAddress } from "@whitelabel-webapp/address/shared/models";
import {
  Catalog,
  CatalogResponse,
} from "@whitelabel-webapp/catalog/shared/models";
import {
  DeliveryMethod,
  MerchantResponse,
} from "@whitelabel-webapp/merchant/shared/models";
import { withMerchantData } from "@whitelabel-webapp/merchant/shared/ssr";
import { MerchantShowcase } from "@whitelabel-webapp/merchant/showcase";
import { EmptyState as PomodoroEmptyState } from "@whitelabel-webapp/shared/design-system";
import { GetServerSideProps } from "next";
import NextImage from "next/image";
import { useEffect } from "react";
import styled from "styled-components";

import {
  List,
  CatalogLayout,
  BigBanners,
  SmallBanners,
} from "@app/domains/catalog";
import { catalogAboyeur } from "@app/domains/catalog/aboyeur";
import { CarouselCategory } from "@app/domains/category";
import {
  CatalogFeatureToggles,
  getCatalogFeatureToggles,
} from "@app/domains/catalog/utils-ssr";
import { Flex } from "@ifood/pomodoro-components";
import { useRouter } from "next/router";
import { appAboyeur } from "@whitelabel-webapp/shared/config";

const EmptyState = styled(PomodoroEmptyState)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 42px;

  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: center;
`;

type ServerProps = {
  featureToggles?: CatalogFeatureToggles;
  merchantResponse?: MerchantResponse;
  catalogResponse?: CatalogResponse;
  serverError?: string;
  merchantPath?: string;
  isCanaryModeEnabled?: boolean;
};

export type HomePageProps = ServerProps & {
  initialCustomerAddress?: CustomerAddress;
  initialDeliveryMethod?: DeliveryMethod;
};

const HomePage: React.VFC<HomePageProps> = ({
  merchantPath,
  merchantResponse,
  catalogResponse,
  initialCustomerAddress,
  initialDeliveryMethod,
  featureToggles,
  isCanaryModeEnabled = false,
}) => {
  const hasCatalog = Boolean(catalogResponse?.categories.length);
  const router = useRouter();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const redirectParam = urlParams.get("redirectSiteMercado");

    if (redirectParam) {
      appAboyeur.events.redirect.siteMercado();
      urlParams.delete("redirectSiteMercado");
      const newUrl = location.pathname;
      router.replace(newUrl, undefined, { shallow: true });
    }
  }, [router]);

  useEffect(() => {
    if (hasCatalog) return;
    catalogAboyeur.events.catalog.empty();
  }, [hasCatalog]);

  return (
    <CatalogLayout
      pageName="home"
      pageTitle="Início"
      merchantPath={merchantPath}
      merchantResponse={merchantResponse}
      catalogResponse={catalogResponse}
      initialCustomerAddress={initialCustomerAddress}
      initialDeliveryMethod={initialDeliveryMethod}
      featureToggles={featureToggles}
      isCanaryModeEnabled={isCanaryModeEnabled}
    >
      <Flex
        flexDirection="column"
        maxWidth="1200px"
        margin="0 auto"
        gap="clamp(32px, 4dvw, 48px)"
      >
        <MerchantShowcase />
        <BigBanners />
        <SmallBanners />
        <CarouselCategory />
        {hasCatalog && <List />}
        {!hasCatalog && (
          <EmptyState
            title="Nada por aqui"
            description="Parece que não temos itens disponíveis no momento."
          >
            <NextImage
              src="/images/error.png"
              alt=""
              width={291}
              height={286}
              layout="fixed"
            />
          </EmptyState>
        )}
      </Flex>
    </CatalogLayout>
  );
};

export const getServerSideProps: GetServerSideProps<HomePageProps> =
  withMerchantData(async (context) => {
    const isCanaryModeEnabled = Boolean(context.req.headers["x-ifood-canary"]);
    const { logger, path, merchantResponse } = context.merchantData;
    const { catalogGroup } = merchantResponse.contextSetup;
    const { uuid } = merchantResponse;

    logger.info({
      message: "Fetching home catalog",
      context: {
        uuid,
        catalogGroup,
      },
    });

    const featureToggles = getCatalogFeatureToggles(merchantResponse);
    const catalogResponse = await Catalog.getSSRCatalog(uuid, catalogGroup);

    return {
      props: {
        merchantPath: path,
        featureToggles: featureToggles,
        merchantResponse,
        catalogResponse,
        isCanaryModeEnabled,
      },
    };
  });

export default HomePage;
